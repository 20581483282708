
.about-page {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    margin-bottom: 100px;

    .text-zone {
        display: flex;
        flex-direction: column;
        width: 45%;
        margin: 20px 20px;
        padding-top: 20px;
        margin-left: 60px;
        margin-top: 30px;
        height: 100%;

        h1 {
            font-size: 64px;
            font-family: 'Syne';
            color: rgb(0, 0, 213);
            font-weight: 600;
            margin-top:0;
            position: relative;
            margin-bottom: 5px;

        }
        p {
            font-size: 13px;
            color: black;
            font-family: 'Syne';
            max-width: fit-content;
            animation: pulse 1s;
            animation-delay: 1.1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }
}

.stage-cube-cont {
    width: 50%;
    height: 50%;
    top: 0;
    padding-top: 17%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
    align-items: center;
}
// .stage-cube-cont {
//     width: 50%;
//     height:100%;
//     top: 0;
//     padding-top: 14%;
//     margin-left:0;
//     position: absolute;
//     right: 0;
//     overflow: hidden;
//     padding-bottom: 60px;
// }

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background: rgba(255, 255, 255, 0.4);
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px lightsteelblue;     
    }

    .face1 {
        transform: translateZ(100px);
        color: #dd0031;
    }
    .face2 {
        transform: rotateY(90deg) translateZ(100px);
        color: #f06529;
    }
    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color: #28a4d9;
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color: #5ed4f4;
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color: #efd81d;
    }
    .face6 {
        transform: rotateX(-90) translateZ(100px);
        color: #ec4d28;
    }
}

@keyframes spincube {
    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }
    33% {
        transform: rotateY(-90deg) rotateX(90deg);
    }
    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
        transform: rotateX(90deg);
    }
}





.flat-button {
    color: rgb(0, 0, 213);
    width: fit-content;
    text-align: center;
    font-size: 13px;
    font-weight: 400px;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding:10px 18px;
    border: 1px solid rgb(0, 0, 213);
    margin-top: 15px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
        background: rgb(0, 0, 213);
        color: white;
    }
}

@media screen and (max-width: 768px) {
    .about-page .stage-cube-cont {
        display: none;
    }

    .about-page .text-zone {
        width: 100%;
        margin: 20px 20px;

        h1 {
            text-align: center;
        }
    }

}