.footer-container {
    display: flex;
    flex-direction: row;
    margin: auto 10px;
    justify-content: center;
    align-items: end;
}
.placeholder {
    width: 20%;
}

.text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2%;
    width: 60%;

    h1 {
        font-size: 12px;
        font-weight: 300;
        font-family: 'Syne';
        span{
            font-size: 20px;
        }
    }

}

.social-list {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 20%;
    gap: 10px;
    padding: 0 20px;

    li {
        list-style: none;
        font-size: 12px;
    }
}

@media screen and (max-width: 768px) {
    .footer-container {
        gap: 5%;
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }
    .footer-container .placeholder {
        display: none;
    }

    .footer-container .text-container {
        width: 70%;
        gap: 8%;

        h1 {
            font-size: 10px;
        }
    
    }
    .footer-container .social-list {
        padding: 0;
        margin-bottom: 5px;
        gap: 5px;
    }

}
