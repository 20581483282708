
.home-page {
    display: flex;
    flex-direction: row;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    margin-bottom: 100px;
    margin-top: 25px;

    .text-zone {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 20px 20px;
        padding-top: 20px;
        margin-left: 60px;
        margin-top: 30px;
        height: 100%;
        z-index: 1000;

        h1 {
            font-size: 64px;
            font-family: 'Poppins';
            color: rgb(211, 0, 0);
            font-weight: 600;
            margin-top:0;
            position: relative;
            margin-bottom: 5px;

            // &::before {
            //     content:'<h1/>';
            //     font-family: 'Source Code';
            //     color: rgb(211, 0, 0);
            //     font-size: 18px;
            //     position: absolute;
            //     margin-top: -40px;
            //     left: 15px;
            //     opacity: 0.6;
            // }

            // &::after {
            //     content: '<h1/>';
            //     font-family: 'Source Code';
            //     color: rgb(211, 0, 0);
            //     font-size: 18px;
            //     position: absolute;
            //     margin-top: 18px;
            //     margin-left: 20px;
            //     animation: fadeIn 1s 1.7s backwards;
            //     opacity: 0.6;
            // }
        }
        h2 {
            color: rgb(211, 0, 0);
            margin-top: 20px;
            font-weight: 400;
            font-size: 11px;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;
        }

        .flat-button {
            color: rgb(211, 0, 0);
            font-size: 13px;
            font-weight: 400px;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding:10px 18px;
            border: 1px solid rgb(211, 0, 0);
            margin-top: 25px;
            float: left;
            animation: fadeIn 1s 1.8s backwards;
            white-space: nowrap;

            &:hover {
                background: rgb(211, 0, 0);
                color: white;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin-top: 25px;
            margin-bottom: 0;
            animation: fadeIn 1s forwards;
            animation-delay: 1s;

            li {
                display: inline-block;

                a {
                    padding: 0 7px;
                    display: block;
                    font-size: 15px;
                    line-height: 16px;
                }

            }
        }
    }
}

// .home-page {

//     .text-zone {
//         position: absolute;
//         left: 10%;
//         top: 50%;
//         transform: translateY(-50%);
//         width: 40%;
//         max-height: 90%;
//     }
//     h1 {
//         color: rgb(211, 0, 0);
//         font-size: 55px;
//         margin: 0;
//         font-family: 'Syne';
//         font-weight: 800;

//         &::before {
//             content:'<h1/>';
//             font-family: 'Source Code';
//             color: rgb(211, 0, 0);
//             font-size: 18px;
//             position: absolute;
//             margin-top: -40px;
//             left: 15px;
//             opacity: 0.6;
//         }

//         &::after {
//             content: '<h1/>';
//             font-family: 'Source Code';
//             color: rgb(211, 0, 0);
//             font-size: 18px;
//             position: absolute;
//             margin-top: 18px;
//             margin-left: 20px;
//             animation: fadeIn 1s 1.7s backwards;
//             opacity: 0.6;
//         }

//         img {
//             width: 32px;
//             margin-left: 20px;
//             opacity: 0;
//             height: auto;
//             animation: rotateIn 1s linear both;
//             animation-delay: 1.4s;
//         }
//     }

//     h2 {
//         color: rgb(211, 0, 0);
//         margin-top: 20px;
//         font-weight: 400;
//         font-size: 11px;
//         font-family: sans-serif;
//         letter-spacing: 3px;
//         animation: fadeIn 1s 1.8s backwards;
//     }

//     .flat-button {
//         color: rgb(211, 0, 0);
//         font-size: 13px;
//         font-weight: 400px;
//         letter-spacing: 4px;
//         font-family: sans-serif;
//         text-decoration: none;
//         padding:10px 18px;
//         border: 1px solid rgb(211, 0, 0);
//         margin-top: 25px;
//         float: left;
//         animation: fadeIn 1s 1.8s backwards;
//         white-space: nowrap;

//         &:hover {
//             background: rgb(211, 0, 0);
//             color: black;
//         }
//     }

//     ul {
//         list-style: none;
//         padding: 0;
//         margin-top: 25px;
//         margin-bottom: 0;

//         li {
//             display: inline-block;

//             a {
//                 padding: 0 7px;
//                 display: block;
//                 font-size: 15px;
//                 line-height: 16px;
//             }

//         }
//     }
// }

@media screen and (max-width: 768px) {
    .home-page {
        height: 100vh;
        margin-bottom: 0;
    }

    .home-page .text-zone {
        width: 100%;
        margin: 20px 20px;

        h1 {
        font-size: 32px;
        }
    }

    .logo-container {
        right: 0;
        height: auto;
    }
}


//     .tags,
//     .home-page h1:before,
//     .home-page h1:after {
//       display: none;
//     }
  
//     .home-page .text-zone {
//       position: initial;
//       margin-top: 15%;
//       width: 100%;
//       transform: none;
//       padding: 10px;
//       box-sizing: border-box;
//       margin-left: 30px;
//     }
  
//     .home-page .flat-button {
//       float: left;
//       display: block;
//       margin: 20px auto 0 auto;
//       width: 124px;
//     }
  
//     .logo-container {
//       position: relative;
//       width: 100px;
//       height: auto;
//       top: 50px;
//       right: 0;
//       box-sizing: border-box;
//       margin: auto;
//       left: 0;
//     }
  
//     .logo-container svg {
//       position: absolute;
//       top: auto;
//       right: auto;
//       bottom: auto;
//       left: 0;
//       margin: auto;
//     }

//     .home-page h1 {
//         font-size: 44px;
//     }
//   }