.nav-bar {
    background: white;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    padding: 10px 60px;
    position: sticky; 
    top: 0;
    z-index:9999;

    .logo {
        margin: 8px 0;
        width: 50%;
        font-size: 22px;
        text-decoration: none;
        color: black;
        font-weight: 350;
        font-family: 'Syne';
        letter-spacing: -1px;
    }

    .mobile-nav {
        display: none;
    }

    .mobile-nav-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top:20px;
        height: 100%;
        width: 100%;
        background: white;
        position: fixed;
        z-index: 1000;
        align-items: center;
        width: 100%;
        gap: 0;
    }

    .mobile-nav-menu {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 1001;
        font-size: 1.5rem;
    }

    nav {
        display: flex;
        text-align: center;
        justify-content: end;
        align-items: center;
        gap: 30px;
        width: 50%;

        a {
            font-size: 18px;
            font-weight: 350;
            color: #000000;
            text-decoration: none;
            font-family: 'Syne';

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: black;
                text-decoration: line-through;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        }
        

        a.active {
            text-decoration: line-through;
            
            svg {
                color: black;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .nav-bar {
        flex-direction: column;
        padding: 0;

        .logo {
            padding: 15px 40px;
        }
    }

    .nav-bar .mobile-nav {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-200%, 95%);
        font-size: 1.8rem;
        cursor: pointer;
        color: black;
    }

    .nav-bar nav {
        display: none;
    }

    // .nav-bar { 
    //   background: transparent;
    //   position: initial;
    //   height: auto;
    //   min-height: auto;
  
    //    nav {
    //     display: none;
    //    }
  
    //    nav {
    //     width: 100%;
    //     top: 0;
    //     left: 0;
    //     background: #181818;
    //     height: 100%;
    //     margin: 0;
    //     z-index: 2;
    //     position: fixed;
  
    //     &.mobile-show {
    //       display: block;
    //     }
  
    //     a {
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }
  
    //     a:after {
    //       opacity: 1;
    //       position: initial;
    //       width: auto;
    //       margin-left: 10PX;
    //     }
  
    //     a svg {
    //       opacity: 1 !important;
    //     }
    //    }
  
    //    .hamburger-icon,
    //    .close-icon {
    //       position: absolute;
    //       top: 15px;
    //       right: 15px;
    //       display: block;
    //    }
//     }
// }


}