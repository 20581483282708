.logo-container {
    z-index: 0;
    width: 450px;
    height: 510px;
    position: absolute;
    top: 0;
    right: 10%;
    bottom: 0;
    left: auto;
    margin: auto;
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        transform: rotateZ(5deg) !important;
    }

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        transform: rotateZ(5deg);
        z-index: 0;
    }
}

.svg-container {
    stroke:rgb(211, 0, 0);
    stroke-width: 10;
}

@media screen and (max-width: 1200px) {
    .logo-container {
        display: none;
    }
    .solid-logo {
        display: none;
    }
}