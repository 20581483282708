.App {
  display: flex;
  flex-direction: column;
  overflow-y:auto;
  height: 100vh;
}

// .page {
//     width: 100%;
//     height: 100%;
//     position: absolute;
// }

// .top-tags {
//     bottom: auto;
//     top: 35px;
// }

// .tags {
//     color: rgb(211, 0, 0);
//     opacity: 0.6;
//     position: absolute;
//     bottom: 0;
//     left: 120px;
//     font-size: 18px;
//     font-family: 'Source Code'
// }


// .bottom-tag-html {
//     margin-left: -20px;
//     color: rgb(211, 0, 0);
// }

// .container {
//     width: 100%;
//     will-change: contents;
//     height: 90%;
//     min-height: 566px;
//     position: absolute;
//     opacity: 0;
//     margin: 0 auto;
//     z-index: 1;
//     transform-style: preserve-3d;
//     animation: fadeIn 1s forwards;
//     animation-delay: 1s;
// }

// .container.about-page,
// .container.contact-page {
//     .text-zone {
//         position: absolute;
//         left: 5%;
//         top: 45%;
//         transform: translateY(-50%);
//         width: 40%;
//         vertical-align: middle;
//         display: table-cell;
//         max-height: 90%;

//         h1 {
//             font-size: 64px;
//             font-family: 'Syne';
//             color: rgb(0, 0, 213);
//             font-weight: 500;
//             margin-top:0;
//             position: relative;
//             margin-bottom: 40px;
//             left: 10px;

//             &:before {
//                 content: '<h1>';
//                 font-family: 'Source Code';
//                 font-size: 18px;
//                 position: absolute;
//                 margin-top: -20px;
//                 left: -10px;
//                 opacity: 0.6;
//                 line-height: 18px;;
//             }

//             &:after {
//                 content: '<h1/>';
//                 font-family: 'Source Code';
//                 font-size: 18px;
//                 line-height: 18px;
//                 position: absolute;
//                 left: -30px;
//                 bottom: -20px;
//                 margin-left: 20px;
//                 opacity: 0.6;
//             }
//         }

//         p {
//             font-size: 13px;
//             color: black;
//             font-family: 'Syne';
//             max-width: fit-content;
//             animation: pulse 1s;

//             &:nth-of-type(1) {
//                 animation-delay: 1.1s;
//             }

//             &:nth-of-type(2) {
//                 animation-delay: 1.2s;
//             }

//             &:nth-of-type(3) {
//                 animation-delay: 1.3s;
//             }
//         }
//     }

//     .text-animate-hover {
//         &:hover {
//             color: black;
//           }
//     }
// }

// @media screen and (max-width: 900px) {
//     .page {
//       position: initial;
//     }
  
//     .container {
//         position: initial;
//         height: auto;
//         min-height: auto;
  
//       &.contact-page,
//       &.about-page {
//         .text-zone {
//             position: initial;
//             transform: none;
//             width: 100%;
//             display: block;
//             padding: 20px;
//             box-sizing: border-box;
//         }
//       }
//     }

//     .stage-cube-cont {
//         position: initial;
//         width: 100%;
//         height: 0%;
//         overflow: visible;
//     }
// }
