$primary-color: black;

@import 'animate.css';
// @import '~loaders.css/src/animations/pacman.scss';
@import 'loaders.css/src/animations/line-scale-pulse-out.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins.woff2') format('woff2');
}

@font-face {
  font-family: 'Oi';
  src: url('./assets/fonts/oi.woff2') format('woff2');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Source Code';
  src: url('./assets/fonts/source-code.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('./assets/fonts/space-grotesk.woff2') format('woff2');
}

@font-face {
  font-family: 'Syne';
  src: url('./assets/fonts/syne.woff2') format('woff2');
}


input,
textarea {
  font-family: 'Helvetica Neue'
}

// .main-container {
//   overflow-y: auto;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 768px) {
  .mainContainer {
    height: 100%;
  }

  
}